var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "admin"
  }, [_c('h2', [_vm._v("ipLlist")]), _c('pre', [_vm._v("connected: " + _vm._s(_vm.isWsConnected))]), _c('div', {
    staticClass: "btn color-red",
    on: {
      "click": function click($event) {
        return _vm.$socket.open();
      }
    }
  }, [_vm._v("open")]), _c('div', {
    staticClass: "btn color-red",
    on: {
      "click": function click($event) {
        return _vm.$socket.emit('ALLLOGS', _vm.whichDay);
      }
    }
  }, [_vm._v("Call logs")]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.$socket.emit('IPCALLS');
      }
    }
  }, [_vm._v("GetIpList")]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.$socket.disconnect();
      }
    }
  }, [_vm._v("disconnect")]), _c('span', [_vm._v("· · · · · · · · · · · · · · · · · · · ·")]), _c('div', {
    staticClass: "btn _green",
    on: {
      "click": function click($event) {
        _vm.view = 'ip';
      }
    }
  }, [_vm._v("ip")]), _c('div', {
    staticClass: "btn _green",
    on: {
      "click": function click($event) {
        _vm.view = 'logs';
      }
    }
  }, [_vm._v("logs")]), _c('div', {
    staticClass: "btn _red",
    on: {
      "click": function click($event) {
        _vm.view = 'errors';
      }
    }
  }, [_vm._v("errors")]), _c('div', {
    staticClass: "btn _orange",
    on: {
      "click": function click($event) {
        _vm.view = 'debug';
      }
    }
  }, [_vm._v("debug")]), _c('div', {
    staticClass: "btn _purple",
    on: {
      "click": function click($event) {
        _vm.view = 'auth';
      }
    }
  }, [_vm._v("auth")]), _c('div', {
    staticClass: "btn _yellow",
    on: {
      "click": function click($event) {
        _vm.view = 'warns';
      }
    }
  }, [_vm._v("warns")]), _c('div', {
    staticClass: "btn _yellow",
    on: {
      "click": function click($event) {
        _vm.view = 'chart';
      }
    }
  }, [_vm._v("chart")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.whichDay,
      expression: "whichDay"
    }],
    domProps: {
      "value": _vm.whichDay
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.whichDay = $event.target.value;
      }
    }
  }), _c('br'), _c('br'), _vm.view == 'ip' ? _c('span', [_c('ul', {
    staticClass: "ip-list"
  }, _vm._l(_vm.ipCalls, function (ip, idx) {
    return _c('li', {
      staticClass: "ipitem",
      class: {
        _redcol: !ip.allowed,
        _bgred: ip.allowed && ((ip.blacklist || {}).totalReports || 0) > 2
      }
    }, [_c('div', {
      staticClass: "flx"
    }, [_c('div', {
      staticClass: "bold"
    }, [_c('h4', [_vm._v(_vm._s((ip.info || {}).ip || (ip.blacklist || {}).ipAddress || idx) + " (" + _vm._s((ip.urls || []).length) + ")")]), _c('div', [_vm._v(_vm._s((ip.info || {}).country_name) + " / " + _vm._s((ip.info || {}).country_capital) + " / " + _vm._s((ip.info || {}).timezone))]), _c('div', [_vm._v(_vm._s((ip.info || {}).org))])]), _c('div', {
      staticClass: "div"
    }, [_c('div', [_vm._v("blacklist: " + _vm._s((ip.blacklist || {}).totalReports) + " / abuseipdb: " + _vm._s((ip.blacklist || {}).abuseConfidenceScore) + " / cleantalk: " + _vm._s((ip.cleantalk || {}).frequency))])]), _c('div', {
      staticClass: "thin"
    }, [ip.allowed ? _c('span', [_c('div', {
      staticClass: "link bold",
      on: {
        "click": function click($event) {
          return _vm.permitIp(ip._id);
        }
      }
    }, [_vm._v("Permit IP")])]) : !ip.allowed ? _c('span', [_c('div', {
      staticClass: "link bold",
      on: {
        "click": function click($event) {
          return _vm.permitIp(ip._id, true);
        }
      }
    }, [_vm._v("Allow IP")])]) : _vm._e(), _c('div', {
      staticClass: "link bold",
      on: {
        "click": function click($event) {
          return _vm.resetIp(ip._id);
        }
      }
    }, [_vm._v("RESET IP")]), _c('a', {
      staticClass: "link bold",
      attrs: {
        "href": 'https://cleantalk.org/blacklists/' + (ip.info || {}).ip,
        "target": "_blank"
      }
    }, [_vm._v("check blacklist")]), _c('div', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(_vm.$dayjs(ip.modified).format(_vm.MAIN_DATE_FORMAT_FULL)))])])]), _c('hr'), _c('div', {
      staticClass: "tac"
    }, [_c('div', {
      staticClass: "link bold",
      on: {
        "click": function click($event) {
          _vm.shocalls[ip._id] = !_vm.shocalls[ip._id];
        }
      }
    }, [_vm._v("SHOW/CLOSE(" + _vm._s((ip.urls || []).length) + ")")])]), _vm.shocalls[ip._id] ? _c('div', {
      staticClass: "thin"
    }, [_c('ul', _vm._l(ip.urls, function (call, ts) {
      return _c('li', {
        staticClass: "flx"
      }, [_c('small', [_vm._v(_vm._s(_vm.$dayjs((call.ts + '').length < 11 ? call.ts * 1000 : call.ts).format(_vm.MAIN_DATE_FORMAT_FULL)) + ": " + _vm._s(call.endPoint))])]);
    }), 0)]) : _vm._e()]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "card"
  }, [_vm.view == 'chart' ? _c('span', [_c('line-chart', {
    attrs: {
      "chartdata": _vm.chartData,
      "options": _vm.chartOptions
    }
  }), _c('pre', [_vm._v(_vm._s(_vm.authData))])], 1) : _vm._e()]), _c('div', {
    staticClass: "card"
  }, [_vm.view == 'logs' ? _c('span', _vm._l(_vm.reverseApiLogs, function (log) {
    return _c('pre', {
      staticClass: "wsnormal"
    }, [_vm._v(_vm._s(log.timestamp) + ":"), _c('span', [_vm._v(_vm._s(log.message))])]);
  }), 0) : _vm._e(), _vm.view == 'errors' ? _c('span', _vm._l(_vm.reverseApiErrors, function (log) {
    return _c('pre', [_vm._v(_vm._s(log.timestamp) + ":"), _c('span', [_vm._v(_vm._s(log.message))])]);
  }), 0) : _vm._e(), _vm.view == 'debug' ? _c('span', _vm._l(_vm.reverseApiDebug, function (log) {
    return _c('pre', {
      staticClass: "wsnormal"
    }, [_vm._v(_vm._s(log.timestamp) + ":"), _c('span', [_vm._v(_vm._s(log.message))])]);
  }), 0) : _vm._e(), _vm.view == 'auth' ? _c('span', _vm._l(_vm.reverseApiAuth, function (log) {
    return _c('pre', {
      staticClass: "wsnormal"
    }, [_vm._v(_vm._s(log.timestamp) + ":"), _c('span', [_vm._v(_vm._s(log.message))])]);
  }), 0) : _vm._e(), _vm.view == 'warns' ? _c('span', [_c('pre', {
    staticClass: "wpre"
  }, [_vm._v(_vm._s(_vm.reverseApiWarns))])]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };